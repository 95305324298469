import gql from "graphql-tag";

export const EXPERIMENTAL_FEATURES_ENABLED = gql`
query EXPERIMENTAL_FEATURES_ENABLED {
  experimentalFeaturesEnabled
}
`;

export const RECIPE_OVERVIEW_DATA = gql`
  fragment RecipeOverviewData on Recipe {
    id
    name
    book {
        name
        formattedName
    }
    ingredients {
        id
        name
    }
    canMake
    linkedRecipes {
        id
        name
        book {
            name
            formattedName
        }
        pageNumber
    }
    favorite
    pageNumber
  }
`;

export const LOAD_SEARCH_TERMS = gql`
{
  searchTerms @client {
    id
    name
    type
    inverted
    minMaxAmount {
        minimum
        maximum
        typeOfMeasurement
    }
  }
  selectedSortingStrategy
  selectedBuildTypes {
    data
    key
  }
  selectedTypes {
    data
    key
  }
  selectedCocktailCategories {
    data
    key
  }
  selectedPersonalFilters {
    data
    key
  }
  selectedBooks {
    data
    key
  }
  minMaxIngredients
  minMaxABV
  minMaxAlcoholicUnits
  minMaxSweetness
}
`;

export const ON_FAVORITE = gql`
  mutation onFavorite($id: String!, $favorite: Boolean!) {
    setFavorite(id: $id, favorite: $favorite) {
      id
      favorite
    }
  }
`;

export const GET_RECIPES = gql`
query GET_RECIPES($filters: [Filter!], $advancedFilters: AdvancedFilters, $offset: Int!, $limit: Int!) {
  recipes(filters: $filters, advancedFilters: $advancedFilters, offset: $offset, limit: $limit) {
    ...RecipeOverviewData
  }
  recipesLength(filters: $filters, advancedFilters: $advancedFilters, offset: $offset, limit: $limit)
}
  ${RECIPE_OVERVIEW_DATA}
`;

export const GET_SEARCH_ITEMS_AND_FILTER_DATA = gql`
query GET_SEARCH_ITEMS {
  searchItems {
    text
    type
  }
  buildTypes
  types
  cocktailCategories
  personalFilters {
    id
    name
  }
  books {
    name
  }
  sortingStrategies
  preferredSortingStrategy
}
`;

export const GET_RECIPE_FULL = gql`
query GET_RECIPE_FULL($bookFormattedName: String, $nameWithoutSpaces: String, $recipeId: String) {
  recipe(bookFormattedName: $bookFormattedName, nameWithoutSpaces: $nameWithoutSpaces, recipeId: $recipeId) {
    id
    name
    instructions
    comments {
      id
      date
      text
    }
    postDilutionAbv
    alcoholicUnits
    preDilutionVolume
    postDilutionVolume
    calories
    sugarPerLiter
    cocktailCategory
    ingredients {
        id
        name
        amount
        typeOfMeasurement
        bottles
    }
    garnish
    glass
    pageNumber
    servings
    tags {
        id
        name
    }
    book {
        name
        formattedName
    }
    mixingTechnique
    userCreated
    isLocked
  }
  tags {
    id
    name
  }
}
`;

export const GET_RECIPE_DESCRIPTION = gql`
query GET_RECIPE_DESCRIPTION($bookFormattedName: String, $nameWithoutSpaces: String, $recipeId: String) {
  recipe(bookFormattedName: $bookFormattedName, nameWithoutSpaces: $nameWithoutSpaces, recipeId: $recipeId) {
    id
    description {
      salesPitch
      description
      ingredientDescriptions {
        ingredientId
        description
      }
    }
  }
}
`;

export const DELETE_TAG = gql`
  mutation deleteTag($recipeId: String!, $tag: String!) {
    deleteTag(recipeId: $recipeId, tag: $tag) {
        id
        tags {
            id
            name
        }
    }
  }
`;

export const ADD_TAG = gql`
  mutation addTag($recipeId: String!, $tag: String!) {
    addTag(recipeId: $recipeId, tag: $tag) {
        id
        tags {
            id
            name
        }
    }
  }
`;

export const SAVE_COMMENT = gql`
  mutation saveComment($recipeId: String!, $commentId: String, $comment: String!) {
    saveComment(recipeId: $recipeId, commentId: $commentId, comment: $comment) {
        id
        comments {
          id
          date
          text
        }
    }
  }
`;

export const GET_ALL_RECIPES_FOR_INGREDIENTS_SEARCH = gql`
query GET_ALL_RECIPES_FOR_INGREDIENTS_SEARCH($search: String) {
  searchRecipes(search: $search) {
    id
    name
    book {
        name
    }
  }
}
`;

export const GET_ALL_INGREDIENTS = gql`
query GET_ALL_INGREDIENTS {
  ingredients {
    id
    name
    recipeCount {
        total
        canMake
        lastIngredientMissing
    }
    owned
    prioritized
    bottles {
      id
      name
      numberOfRecipes
    }
  }
  prioritizedExhaustionIngredients
  prioritizedUsageOrderingEnabled
}
`;

export const ON_CAN_MAKE = gql`
  mutation ON_CAN_MAKE($id: String!) {
    canMake(id: $id) {
        id
        canMake
    }
  }
`;

export const ON_REMOVE_OWNED_INGREDIENT = gql`
  mutation ON_REMOVE_OWNED_INGREDIENT($id: String!, $ingredient: String!) {
    removeOwnedIngredient(id: $id, ingredient: $ingredient) {
        id
        canMake
    }
  }
`;

export const INVERT_INGREDIENT = gql`
  mutation INVERT_INGREDIENT($ingredient: String!) {
    invertIngredient(ingredient: $ingredient)
  }
`;

export const INVERT_PRIORITIZE_INGREDIENT_USAGE = gql`
  mutation INVERT_PRIORITIZE_INGREDIENT_USAGE($ingredient: String!) {
    invertPrioritizeIngredientUsage(ingredient: $ingredient)
  }
`;

export const REORDER_PRIORITIZED_INGREDIENT_USAGES = gql`
  mutation REORDER_PRIORITIZED_INGREDIENT_USAGES($ingredients: [String!]!) {
    reorderPrioritizedIngredientUsages(ingredients: $ingredients)
  }
`;

export const TOGGLE_PRIORITIZED_INGREDIENT_USAGES = gql`
  mutation TOGGLE_PRIORITIZED_INGREDIENT_USAGES($enabled: Boolean!) {
    setPrioritizedUsageOrderingEnabled(enabled: $enabled)
  }
`;

export const REGISTER_LOGIN = gql`
  mutation REGISTER_LOGIN($id: String!) {
    registerLogin(id: $id)
  }
`;

export const SET_PREFERRED_SORTING_STRATEGY = gql`
  mutation SET_PREFERRED_SORTING_STRATEGY($sortingStrategy: String!) {
    setPreferredSortingStrategy(sortingStrategy: $sortingStrategy)
  }
`;

export const GET_PANTRY_PROFILES = gql`
query GET_PANTRY_PROFILES {
  pantryProfiles {
    id
    title
    active
    activeUntil
    ingredients {
      id
      name
    }
  }
}
`;

export const CREATE_NEW_PANTRY_PROFILE = gql`
  mutation CREATE_NEW_PANTRY_PROFILE($title: String!, $ingredient: String) {
    createPantryProfile(title: $title, ingredient: $ingredient) {
      id
      title
      active
      activeUntil
      ingredients {
        id
        name
      }
    }
  }
`;

export const ADD_INGREDIENT_TO_PANTRY_PROFILE = gql`
  mutation ADD_INGREDIENT_TO_PANTRY_PROFILE($pantryProfileId: String!, $ingredient: String!) {
    addIngredientToPantryProfile(pantryProfileId: $pantryProfileId, ingredient: $ingredient) {
      id
      title
      active
      activeUntil
      ingredients {
        id
        name
      }
    }
  }
`;

export const REMOVE_INGREDIENT_FROM_PANTRY_PROFILE = gql`
  mutation REMOVE_INGREDIENT_FROM_PANTRY_PROFILE($pantryProfileId: String!, $ingredient: String!) {
    removeIngredientFromPantryProfile(pantryProfileId: $pantryProfileId, ingredient: $ingredient) {
      id
      title
      active
      activeUntil
      ingredients {
        id
        name
      }
    }
  }
`;

export const TOGGLE_PANTRY_PROFILE = gql`
  mutation TOGGLE_PANTRY_PROFILE($id: String!, $active: Boolean!) {
    togglePantryProfile(id: $id, active: $active) {
      id
      title
      active
      activeUntil
      ingredients {
        id
        name
      }
    }
  }
`;

export const DELETE_PANTRY_PROFILE = gql`
  mutation DELETE_PANTRY_PROFILE($id: String!) {
    deletePantryProfile(id: $id)
  }
`;

export const UPDATE_PANTRY_PROFILE_TITLE = gql`
  mutation UPDATE_PANTRY_PROFILE_TITLE($id: String!, $title: String!) {
    updatePantryProfileTitle(id: $id, title: $title) {
      id
      title
      active
      activeUntil
      ingredients {
        id
        name
      }
    }
  }
`;

export const CREATE_RECIPE = gql`
  mutation CREATE_RECIPE($title: String!) {
    createRecipe(title: $title) {
      id
    }
  }
`;

export const DELETE_RECIPE = gql`
  mutation DELETE_RECIPE($recipeId: String!) {
    deleteRecipe(recipeId: $recipeId)
  }
`;

export const CHANGE_DEFAULT_SERVINGS = gql`
  mutation CHANGE_DEFAULT_SERVINGS($recipeId: String!, $servings: Int!) {
    setDefaultServings(recipeId: $recipeId, servings: $servings) {
      id
      servings
    }
  }
`;

export const ADD_INGREDIENT = gql`
  mutation ADD_INGREDIENT($recipeId: String!, $ingredient: String!, $amount: Float, $typeOfMeasurement: TypeOfMeasurement) {
    addIngredient(recipeId: $recipeId, ingredient: $ingredient, amount: $amount, typeOfMeasurement: $typeOfMeasurement) {
      id
      ingredients {
        id
        name
        amount
        typeOfMeasurement
        bottles
      }
      postDilutionAbv
      alcoholicUnits
      postDilutionVolume
      calories
      sugarPerLiter
      cocktailCategory
    }
  }
`;

export const EDIT_INGREDIENT = gql`
  mutation EDIT_INGREDIENT($recipeId: String!, $ingredientIndex: Float!, $ingredient: String!, $amount: Float, $typeOfMeasurement: TypeOfMeasurement) {
    editIngredient(recipeId: $recipeId, ingredientIndex: $ingredientIndex, ingredient: $ingredient, amount: $amount, typeOfMeasurement: $typeOfMeasurement) {
      id
      ingredients {
        id
        name
        amount
        typeOfMeasurement
        bottles
      }
      postDilutionAbv
      alcoholicUnits
      postDilutionVolume
      calories
      sugarPerLiter
      cocktailCategory
    }
  }
`;

export const REMOVE_INGREDIENT = gql`
  mutation REMOVE_INGREDIENT($recipeId: String!, $ingredient: String!, $index: Int!) {
    removeIngredient(recipeId: $recipeId, ingredient: $ingredient, index: $index) {
      id
      ingredients {
        id
        name
        amount
        typeOfMeasurement
        bottles
      }
      postDilutionAbv
      alcoholicUnits
      postDilutionVolume
      calories
      sugarPerLiter
      cocktailCategory
    }
  }
`;

export const SAVE_INSTRUCTIONS = gql`
  mutation SAVE_INSTRUCTIONS($recipeId: String!, $instructions: String!) {
    saveInstructions(recipeId: $recipeId, instructions: $instructions) {
      id
      instructions
      postDilutionAbv
      alcoholicUnits
      postDilutionVolume
      calories
      sugarPerLiter
      cocktailCategory
      mixingTechnique
    }
  }
`;

export const EDIT_RECIPE_TITLE = gql`
  mutation EDIT_RECIPE_TITLE($recipeId: String!, $title: String!) {
    editRecipeTitle(recipeId: $recipeId, title: $title) {
      id
      name
    }
  }
`;

export const SAVE_GARNISH = gql`
  mutation SAVE_GARNISH($recipeId: String!, $garnish: String!) {
    saveGarnish(recipeId: $recipeId, garnish: $garnish) {
      id
      garnish
    }
  }
`;

export const SAVE_GLASS = gql`
    mutation SAVE_GLASS($recipeId: String!, $glass: String!) {
      saveGlass(recipeId: $recipeId, glass: $glass) {
        id
        glass
      }
    }
`;

export const SAVE_MIXING_TECHNIQUE = gql`
    mutation SAVE_MIXING_TECHNIQUE($recipeId: String!, $mixingTechnique: MixingTechnique!) {
      saveMixingTechnique(recipeId: $recipeId, mixingTechnique: $mixingTechnique) {
        id
        postDilutionAbv
        alcoholicUnits
        postDilutionVolume
        calories
        sugarPerLiter
        cocktailCategory
        mixingTechnique
      }
    }
`;

export const LOCK_RECIPE = gql`
    mutation LOCK_RECIPE($recipeId: String!, $lock: Boolean!) {
      lockRecipe(recipeId: $recipeId, lock: $lock) {
        id
        isLocked
      }
    }
`;

export const GET_ALL_INGREDIENT_NAMES = gql`
query GET_ALL_INGREDIENT_NAMES {
  ingredients {
    id
    name
    recipeCount {
        total
    }
  }
}
`;

export const GET_TASTING_LOG = gql`
query GET_TASTING_LOG($recipeId: String!) {
  recipe(recipeId: $recipeId) {
    id
    tastingLog {
      id
      date
      comment
      person
    }
  }
}
`;

export const GET_PEOPLE = gql`
query GET_PEOPLE {
  people
}
`;

export const CREATE_TASTING_LOG = gql`
    mutation CREATE_TASTING_LOG($recipeId: String!, $comment: String, $date: String, $person: String) {
      createTastingLog(recipeId: $recipeId, comment: $comment, date: $date, person: $person) {
        id
        date
        comment
        person
      }
    }
`;

export const EDIT_TASTING_LOG = gql`
    mutation EDIT_TASTING_LOG($tastingLogId: String!, $comment: String, $date: String, $person: String) {
      editTastingLog(tastingLogId: $tastingLogId, comment: $comment, date: $date, person: $person) {
        id
        date
        comment
        person
      }
    }
`;

export const DELETE_TASTING_LOG = gql`
    mutation DELETE_TASTING_LOG($tastingLogId: String!) {
      deleteTastingLog(tastingLogId: $tastingLogId)
    }
`;