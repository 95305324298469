import * as React from 'react';
import {useState} from 'react';
import {Add} from "@material-ui/icons";
import {
    TimelineConnector,
    TimelineContent,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from "@material-ui/lab";
import {Typography} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {TastingLogDialog} from "./TastingLogDialog";
import {useMutation} from "@apollo/react-hooks";
import {CREATE_TASTING_LOG, GET_TASTING_LOG} from "../utilities/Queries";

type Props = {
    recipe: any;
};
export const AddTastingLog = ({recipe}: Props) => {
    const [dialogOpen, setDialogOpen] = useState(false);

    const [createTastingLog] = useMutation(CREATE_TASTING_LOG, {
        update(cache, {data: {createTastingLog}}) {
            const data = cache.readQuery<{ recipe: { tastingLog: any[] } }>({
                query: GET_TASTING_LOG,
                variables: {recipeId: recipe.id}
            });
            const recipeData = data?.recipe;

            if (recipeData) {
                cache.writeQuery({
                    query: GET_TASTING_LOG,
                    variables: {recipeId: recipe.id},
                    data: {
                        recipe: {
                            ...recipeData,
                            tastingLog: [createTastingLog, ...recipeData.tastingLog]
                        }
                    }
                });
            }
        }
    });

    const onLeftSide = recipe.tastingLog.length % 2 === 0;
    const timelineOppositeContent = <TimelineOppositeContent
        style={{margin: 'auto 0', textAlign: onLeftSide ? "right" : "left"}}
    >
        <Typography variant="caption" style={{fontStyle: "italic"}} color="textSecondary">
            {"Log your thoughts by clicking the plus icon to the " + (onLeftSide ? "right" : "left")}
        </Typography>
    </TimelineOppositeContent>;
    const timelineContent = <TimelineContent
        style={{margin: 'auto 0', textAlign: onLeftSide ? "left" : "right"}}>
        <Typography variant={"body1"}>{"Add a new log"}</Typography>
        <Typography variant={"caption"} style={{fontStyle: "italic"}}
                    color="textSecondary">{"Have you made this today?"}</Typography>
    </TimelineContent>;
    return <>
        <TimelineItem>
            {onLeftSide ? timelineOppositeContent : timelineContent}
            <TimelineSeparator>
                <div style={{height: "10px"}}/>
                <IconButton
                    onClick={() => {
                        setDialogOpen(true);
                    }}
                    style={{
                        backgroundColor: "rgba(212, 175, 55, 0.9)",
                        color: "white",
                        padding: "6px",
                        transition: "background-color 0.3s",
                        marginTop: "8px"
                    }}
                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "rgba(212, 175, 55, 1)"}
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "rgba(212, 175, 55, 0.9)"}>
                    <Add style={{fontSize: "24px"}}/>
                </IconButton>
                {recipe.tastingLog.length === 0 ? <div style={{height: "10px"}}/> : <TimelineConnector/>}
            </TimelineSeparator>
            {onLeftSide ? timelineContent : timelineOppositeContent}
        </TimelineItem>
        <TastingLogDialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            onSave={(comment, date, person) => {
                createTastingLog({
                    variables: {
                        recipeId: recipe.id,
                        comment: comment,
                        date: date,
                        person: person
                    },
                    optimisticResponse: {
                        createTastingLog: {
                            __typename: "TastingLog",
                            id: Math.random().toString(36).substr(2, 9),
                            date: date,
                            comment: comment,
                            person: person
                        }
                    }
                });
                setDialogOpen(false);
            }}
        />
    </>
};