import React from "react";
import {Typography} from "@material-ui/core";
import {Timeline} from "@material-ui/lab";
import {AddTastingLog} from "./AddTastingLog";
import RecipeTastingLogItem from "./RecipeTastingLogItem";
import {useQuery} from "@apollo/react-hooks";
import {GET_TASTING_LOG} from "../utilities/Queries";
import CircularProgress from "@material-ui/core/CircularProgress";

interface RecipeCommentsProps {
    recipeId: string;
    isLoggedIn: boolean;
    isEditable: boolean;
}

const RecipeTastingLog: React.FC<RecipeCommentsProps> = ({
                                                             recipeId,
                                                             isLoggedIn,
                                                             isEditable
                                                         }) => {
    if (!isLoggedIn || isEditable) {
        return undefined;
    }
    const {loading, error, data} = useQuery(GET_TASTING_LOG, {
        variables: {
            recipeId: recipeId
        }
    });
    if (error) {
        console.log(error);
        return undefined;
    }
    const tastingLog = data?.recipe.tastingLog;

    return <div className="flex-column-center">
        <div className="flex-row-center">
            <Typography color="secondary" variant="h6">{"Tasting log"}</Typography>
        </div>
        {loading ? <CircularProgress color={"secondary"} style={{marginTop: "24px"}}/> : (
            <Timeline style={{width: "100%"}}>
                <AddTastingLog recipe={data.recipe}/>
                {tastingLog.map((log: any, i) => (
                    <RecipeTastingLogItem key={i} log={log} index={i} isLastItem={i + 1 === tastingLog.length}
                                          recipe={data.recipe}/>
                ))}
            </Timeline>
        )}
    </div>
};

export default RecipeTastingLog;