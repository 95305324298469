import React from "react";
import ListItem from "@material-ui/core/ListItem";
import {ListItemText} from "@material-ui/core";
import {Link} from "react-router-dom";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import {DeleteRounded} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import {useMutation} from "@apollo/react-hooks";
import {client} from "../../ApolloLayer";
import {DELETE_COCKTAIL_MENU, GET_COCKTAIL_MENUS} from "../CocktailMenuQueries";

export default function CocktailMenuOverviewItem(props) {
    let menu = props.menu;

    const [deleteMenu] = useMutation(DELETE_COCKTAIL_MENU, {
        client: client,
        variables: {
            menuId: menu.id
        },
        refetchQueries: [{query: GET_COCKTAIL_MENUS}]
    });

    const handleDeleteClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        deleteMenu();
    };

    return <Link to={"/cocktailmenu/" + menu.id} style={{textDecoration: "none", color: "inherit"}}>
        <ListItem button>
            <ListItemText primary={menu.name} secondary={menu.description}/>
            <ListItemSecondaryAction>
                <Tooltip title={'Delete "' + menu.name + '"'}>
                    <IconButton edge="end" aria-label="delete" onClick={handleDeleteClick}>
                        <DeleteRounded style={{color: "red"}}/>
                    </IconButton>
                </Tooltip>
            </ListItemSecondaryAction>
        </ListItem>
    </Link>;
}