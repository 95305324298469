import React, {useState} from "react";
import {useMutation} from "@apollo/react-hooks";
import {Box, Button, Typography} from "@material-ui/core";
import {ADD_INGREDIENT} from "../utilities/Queries";
import {client} from "../ApolloLayer";
import EditIngredient from "./EditIngredient";

const AddIngredient = ({recipe}) => {
    const [showNewIngredient, setShowNewIngredient] = useState(false);
    const [addIngredient] = useMutation(ADD_INGREDIENT, {
        client: client
    });

    const save = (amount: string, typeOfMeasurement: string, ingredientName: string) => {
        addIngredient({
            variables: {
                recipeId: recipe.id,
                ingredient: ingredientName,
                amount: parseFloat(amount),
                typeOfMeasurement: typeOfMeasurement
            },
            optimisticResponse: {
                addIngredient: {
                    __typename: "Recipe",
                    id: recipe.id,
                    ingredients: [
                        ...recipe.ingredients,
                        {
                            __typename: "RecipeIngredient",
                            id: ingredientName,
                            name: ingredientName,
                            amount: parseFloat(amount),
                            typeOfMeasurement: typeOfMeasurement,
                            bottles: []
                        }
                    ],
                    postDilutionAbv: recipe.postDilutionAbv,
                    alcoholicUnits: recipe.alcoholicUnits,
                    postDilutionVolume: recipe.postDilutionVolume,
                    calories: recipe.calories,
                    sugarPerLiter: recipe.sugarPerLiter,
                    cocktailCategory: recipe.cocktailCategory
                }
            }
        }).catch(error => {
            console.error("Error adding ingredient:", error);
        });
        cancel();
    }

    const cancel = () => {
        setShowNewIngredient(false);
    }

    return (
        showNewIngredient ? (
            <EditIngredient onSave={save} onCancel={cancel}/>
        ) : recipe.ingredients.length === 0 ? (
            <Button onClick={() => setShowNewIngredient(true)} style={{
                borderRadius: 4,
                margin: "10px",
                padding: "8px",
                border: "1px solid #d4af37",
                backgroundColor: "rgba(212, 175, 55, 0.1)",
                textTransform: "none",
                transition: "background-color 0.3s",
            }}
                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "rgba(212, 175, 55, 0.5)"}
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "rgba(212, 175, 55, 0.1)"}>
                <Box>
                    <Typography variant={"body2"} style={{fontStyle: "italic"}}>
                        {"Start by adding ingredients to your recipe."}
                    </Typography>
                    <br/>
                    <Typography variant={"body2"} style={{fontStyle: "italic"}}>
                        {"Click anywhere in this box to add your first ingredient."}
                    </Typography>
                </Box>
            </Button>
        ) : (
            <Button color="secondary" variant="outlined" style={{marginTop: "10px"}}
                    onClick={() => setShowNewIngredient(true)}>
                {"Add ingredient"}
            </Button>
        )
    );
};

export default AddIngredient;