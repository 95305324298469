import React, {useState} from "react";
import "./FullRecipe.css";
import Typography from "@material-ui/core/Typography";
import {renderIngredients} from "./FullRecipeContent";
import {makeStyles} from "@material-ui/core/styles";
import {
    formatDescription,
    renderAbvCard,
    renderCaloriesCard,
    renderCocktailCategoryCard,
    renderSweetnessCard,
    renderUnitsCard
} from "./RedesignFullRecipeLarge";
import {Box, IconButton} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import {CocktailDescriptionDialog} from "./CocktailDescriptionDialog";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import {DeleteRecipeButton} from "./DeleteRecipeButton";
import RecipeInstructions from "./RecipeInstructions";
import {EditRecipeTitle} from "./EditRecipeTitle";
import {RecipeLock} from "./RecipeLock";
import RecipeTags from "./RecipeTags";
import RecipeTastingLog from "./RecipeTastingLog";

const useStyles = makeStyles(theme => ({
    card: {
        width: "250px"
    },
    ingredients: {
        marginBottom: "10px"
    },
    ingredientsExtras: {
        marginTop: "10px",
        marginBottom: "10px",
        marginLeft: "10px",
        fontStyle: "italic"
    },
    ingredientAmount: {
        width: "33%"
    },
    avatarPersonal: {
        backgroundColor: "inherit",
        color: "#d4af37"
    },
    chip: {
        margin: "5px"
    },
    commentsDialog: {
        width: "400px"
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    appBar: {
        position: "inherit"
    }
}));

export default function RedesignFullRecipeMobile(props) {
    const isUserCreatedRecipe = props.isUserCreatedRecipe;
    const isEditable = props.isEditable;
    const recipe = props.recipe;
    const recipeDescription = props.recipeDescription;
    const allTags = props.allTags;
    const classes = useStyles();

    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return <div className="flex-column-center">
        <div style={{width: "calc(100% - 32px)", marginLeft: "16px", marginRight: "16px"}}>
            <div className="title-and-book">
                <Box style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <Typography variant="h5" style={{textAlign: "center"}} color="secondary">
                        {recipe.name}
                    </Typography>
                    {isEditable && <EditRecipeTitle recipe={recipe}/>}
                    {isEditable && <DeleteRecipeButton recipe={recipe}/>}
                    {isUserCreatedRecipe && <RecipeLock recipe={recipe} isEditable={isEditable}/>}
                </Box>
                <div className="flex-column-center">
                    <Typography variant="h6" color="secondary">
                        {recipe.book.name}
                    </Typography>
                    {recipe.pageNumber &&
                        <Typography variant="h6" color="secondary">
                            {"Page " + recipe.pageNumber + ""}
                        </Typography>
                    }
                </div>
            </div>
            {recipeDescription !== null && (
                <Box my={3}
                     mx="auto"
                     width={"calc(100% - 48px)"}
                     borderRadius={8}
                     p={2}
                     bgcolor={"rgb(244, 244, 244)"}>
                    <Typography variant="body1" style={recipeDescription ? {
                        lineHeight: 1.75,
                        fontFamily: "ui-sans-serif,-apple-system,system-ui,Segoe UI,Helvetica,Apple Color Emoji,Arial,sans-serif,Segoe UI Emoji,Segoe UI Symbol",
                        fontStyle: "italic",
                        display: "flex",
                        alignItems: "center"
                    } : {
                        lineHeight: 1.75,
                        fontFamily: "ui-sans-serif,-apple-system,system-ui,Segoe UI,Helvetica,Apple Color Emoji,Arial,sans-serif,Segoe UI Emoji,Segoe UI Symbol",
                        fontStyle: "italic"
                    }}>
                        {recipeDescription ? formatDescription(recipeDescription.salesPitch) : (
                            <>
                                <Skeleton animation="pulse"/>
                                <Skeleton animation="pulse"/>
                                <Skeleton animation="pulse"/>
                            </>
                        )}
                        {recipeDescription?.description && <IconButton color="secondary" onClick={handleToggleExpand}>
                            <InfoOutlined/>
                        </IconButton>}
                    </Typography>
                </Box>
            )}
            <CocktailDescriptionDialog open={isExpanded} onClose={handleToggleExpand} recipeName={recipe.name}
                                       description={recipeDescription ? recipeDescription.description : ""}/>
            {renderIngredients(recipe, classes, isEditable, true, recipeDescription?.ingredientDescriptions)}
            <div>
                <RecipeInstructions recipe={recipe} isEditable={isEditable}/>
                <RecipeTags
                    recipe={recipe}
                    classes={classes}
                    getRecipeFullVariables={props.getRecipeFullVariables}
                    isMobile={true}
                    isLoggedIn={props.isLoggedIn}
                    isEditable={isEditable}
                    allTags={allTags}
                />
                <RecipeTastingLog recipeId={recipe.id} isLoggedIn={props.isLoggedIn} isEditable={isEditable}/>
            </div>
            {recipe.ingredients.length > 0 && (
                <div className="flex-column-center">
                    <div className="mobile-card">
                        {renderAbvCard(recipe, classes)}
                    </div>
                    <div className="mobile-card">
                        {renderUnitsCard(recipe, classes)}
                    </div>
                    <div className="mobile-card">
                        {renderCaloriesCard(recipe, classes)}
                    </div>
                    <div className="mobile-card">
                        {renderSweetnessCard(recipe, classes)}
                    </div>
                    <div className="mobile-card">
                        {renderCocktailCategoryCard(recipe, classes)}
                    </div>
                </div>
            )}
        </div>
    </div>;
}