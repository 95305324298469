import React, {useState} from "react";
import "./FullRecipe.css";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {makeStyles} from "@material-ui/core/styles";
import {renderIngredients} from "./FullRecipeContent";
import {Box, IconButton} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import {CocktailDescriptionDialog} from "./CocktailDescriptionDialog";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import {DeleteRecipeButton} from "./DeleteRecipeButton";
import RecipeInstructions from "./RecipeInstructions";
import {EditRecipeTitle} from "./EditRecipeTitle";
import {RecipeLock} from "./RecipeLock";
import RecipeTags from "./RecipeTags";
import RecipeTastingLog from "./RecipeTastingLog";

const useStyles = makeStyles(theme => ({
    card: {
        width: "250px",
        marginBottom: "16px"
    },
    ingredients: {
        marginBottom: "10px"
    },
    ingredientsExtras: {
        marginTop: "10px",
        marginBottom: "10px",
        marginLeft: "10px",
        fontStyle: "italic"
    },
    ingredientAmount: {
        width: "33%"
    },
    avatarPersonal: {
        backgroundColor: "inherit",
        color: "#d4af37"
    },
    chip: {
        margin: "5px"
    },
    commentsDialog: {
        width: "400px"
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

export function formatDescription(description) {
    const parts = description.split(/(\*[^*]+\*)/g);
    return parts.map((part, index) => {
        if (part.startsWith('*') && part.endsWith('*')) {
            return <strong key={index}>{part.slice(1, -1)}</strong>;
        }
        return part;
    });
}

export default function RedesignFullRecipeLarge(props) {
    const isUserCreatedRecipe = props.isUserCreatedRecipe;
    const isEditable = props.isEditable;
    const recipe = props.recipe;
    const recipeDescription = props.recipeDescription;
    const allTags = props.allTags;
    const classes = useStyles();

    const [value, setValue] = React.useState(0);
    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return <div className="recipe-outer">
        <div className="recipe">
            <div style={{width: "100%"}}>
                <div className="flex-column-center">
                    <div className="title-and-book">
                        <Box style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <Typography variant="h3" color="secondary">
                                {recipe.name}
                            </Typography>
                            {isEditable && <EditRecipeTitle recipe={recipe}/>}
                            {isEditable && <DeleteRecipeButton recipe={recipe}/>}
                            {isUserCreatedRecipe && <RecipeLock recipe={recipe} isEditable={isEditable}/>}
                        </Box>
                        <div className="flex-column-center">
                            <Typography variant="h6" color="secondary">
                                {recipe.book.name}
                            </Typography>
                            {recipe.pageNumber &&
                                <Typography variant="h6" color="secondary">
                                    {"Page " + recipe.pageNumber + ""}
                                </Typography>
                            }
                        </div>
                    </div>
                </div>
                {recipeDescription !== null && (
                    <Box my={3} mx="auto" width={"718px"} borderRadius={8} p={2}
                         bgcolor={"rgb(244, 244, 244)"}>
                        <Typography variant="body1" style={recipeDescription ? {
                            lineHeight: 1.75,
                            fontFamily: "ui-sans-serif,-apple-system,system-ui,Segoe UI,Helvetica,Apple Color Emoji,Arial,sans-serif,Segoe UI Emoji,Segoe UI Symbol",
                            fontStyle: "italic",
                            display: "flex",
                            alignItems: "center"
                        } : {
                            lineHeight: 1.75,
                            fontFamily: "ui-sans-serif,-apple-system,system-ui,Segoe UI,Helvetica,Apple Color Emoji,Arial,sans-serif,Segoe UI Emoji,Segoe UI Symbol",
                            fontStyle: "italic"
                        }}>
                            {recipeDescription ? formatDescription(recipeDescription.salesPitch) : (
                                <>
                                    <Skeleton animation="pulse"/>
                                    <Skeleton animation="pulse"/>
                                </>
                            )}
                            {recipeDescription?.description &&
                                <IconButton color="secondary" onClick={handleToggleExpand} style={{marginLeft: "8px"}}>
                                    <InfoOutlined/>
                                </IconButton>}
                        </Typography>
                    </Box>
                )}
                <CocktailDescriptionDialog open={isExpanded} onClose={handleToggleExpand} recipeName={recipe.name}
                                           description={recipeDescription ? recipeDescription.description : ""}/>
                <div
                    className={recipe.ingredients.length > 0 ? "ingredients-and-instructions" : "ingredients-and-instructions-empty"}>
                    {renderIngredients(recipe, classes, isEditable, false, recipeDescription?.ingredientDescriptions)}
                    <div className="vertical-divider"/>
                    <div className="instructions-full">
                        <RecipeInstructions recipe={recipe} isEditable={isEditable}/>
                        <RecipeTags
                            recipe={recipe}
                            classes={classes}
                            getRecipeFullVariables={props.getRecipeFullVariables}
                            isMobile={false}
                            isLoggedIn={props.isLoggedIn}
                            isEditable={isEditable}
                            allTags={allTags}
                        />
                        <RecipeTastingLog recipeId={recipe.id}
                                          isLoggedIn={props.isLoggedIn}
                                          isEditable={isEditable}/>
                    </div>
                </div>
                {recipe.ingredients.length > 0 && (
                    <div className="facts">
                        {renderAbvCard(recipe, classes)}
                        {renderUnitsCard(recipe, classes)}
                        {renderCaloriesCard(recipe, classes)}
                        {renderSweetnessCard(recipe, classes)}
                        {renderCocktailCategoryCard(recipe, classes)}
                    </div>
                )}
            </div>
        </div>
    </div>;
}

export function renderAbvCard(recipe, classes) {
    let abv = recipe.postDilutionAbv;
    let abvString = abv.toFixed(1) + "%";

    // We compare using the following recipes:
    // Pilsner 4.4%, Aperol spritz 6.7%, white wine 13%, red wine 14.5%, Margarita 15.5, Martini 23.8%, Old-fashioned 32%
    let innerDescription = "When served, the recipe has an ABV of " + abvString + ". ";
    if (abv < 0.5) {
        return undefined;
    } else if (abv < 4) {
        innerDescription += "That means it is weaker than a pilsner.";
    } else if (abv < 5) {
        innerDescription += "That puts it very close to a pilsner.";
    } else if (abv < 6.3) {
        innerDescription += "That puts it in between a pilsner and an Aperol Spritz.";
    } else if (abv < 7.5) {
        innerDescription += "That puts it very close to an Aperol Spritz.";
    } else if (abv < 11) {
        innerDescription += "That puts it in between an Aperol Spritz and white wine.";
    } else if (abv < 13.2) {
        innerDescription += "That puts it very close to white wine.";
    } else if (abv < 14.1) {
        innerDescription += "That puts it in between white and red wine.";
    } else if (abv < 15) {
        innerDescription += "That puts it very close to red wine.";
    } else if (abv < 21) {
        innerDescription += "That puts it in between red wine and a Martini.";
    } else if (abv < 25) {
        innerDescription += "That puts it close to a Martini.";
    } else if (abv < 30) {
        innerDescription += "That puts it in between a Martini and an Old-fashioned.";
    } else if (abv < 34) {
        innerDescription += "That puts it close to an Old-fashioned.";
    } else if (abv >= 34) {
        innerDescription += "That means it is stronger than an Old-fashioned.";
    }

    return <Card key={"abv-card"} className={classes.card}>
        <CardHeader
            title={abvString + " ABV"}
            subheader={"Alcohol by volume"}
        />
        <CardContent>
            <Typography variant="body2">
                {innerDescription}
            </Typography>
        </CardContent>
    </Card>;
}

export function renderUnitsCard(recipe, classes) {
    let units = recipe.alcoholicUnits;
    let unitsString = recipe.alcoholicUnits.toFixed(1);
    if (unitsString % 1 === 0) {
        unitsString = unitsString.split(".")[0];
    }
    if (unitsString === "1") {
        unitsString += " unit";
    } else {
        unitsString += " units";
    }

    // We compare using the following recipes:
    // Pilsner 4.4%, Aperol spritz 6.7%, white wine 13%, red wine 14.5%, Margarita 15.5, Martini 23.8%, Old-fashioned 32%
    let innerDescription = "This recipe contains " + unitsString + ". ";
    if (units < 0.5) {
        return undefined;
    } else if (units < 0.9) {
        innerDescription += "That means you'll get less drunk drinking this than a pilsner.";
    } else if (units < 1.2) {
        innerDescription += "You'll drink the same amount of alcohol as a pilsner.";
    } else if (units < 1.4) {
        innerDescription += "That puts it in between a pilsner and an Daiquiri.";
    } else if (units < 1.7) {
        innerDescription += "That puts it very close to a Daiquiri, a Whiskey Sour and an Old-fashioned.";
    } else if (units < 2.0) {
        innerDescription += "That puts it in between an Daiquiri and a Margarita.";
    } else if (units < 2.2) {
        innerDescription += "That puts it close to a Margarita.";
    } else if (units < 2.8) {
        innerDescription += "That puts it in between a Margarita and a Long Island Iced Tea.";
    } else if (units < 3.2) {
        innerDescription += "That puts it close to a Long Island Iced Tea.";
    } else if (units < 3.9) {
        innerDescription += "That puts it in between a Long Island Iced Tea and a Zombie.";
    } else if (units < 4.4) {
        innerDescription += "That puts it close to a Zombie. Most restaurants won't serve you more than two of these in an evening!";
    } else if (units >= 4.4) {
        innerDescription += "That means it is stronger than a Zombie!";
    }

    return <Card key={"units-card"} className={classes.card}>
        <CardHeader
            title={unitsString}
            subheader={"Alcoholic units"}
        />
        <CardContent>
            <Typography variant="body2">
                {innerDescription}
            </Typography>
        </CardContent>
    </Card>;
}

export function renderCocktailCategoryCard(recipe, classes) {
    let cocktailCategory = recipe.cocktailCategory;
    let cocktailCategoryName = recipe.cocktailCategory;
    if (cocktailCategoryName === "WhiskeyHighball") {
        cocktailCategoryName = "Whiskey Highball";
    }
    if (cocktailCategoryName === "OldFashioned") {
        cocktailCategoryName = "Old-fashioned";
    }

    let body = "This recipe is a variation on the " + cocktailCategoryName + ". ";
    if (cocktailCategory === "Daiquiri") {
        body += "These cocktails are very refreshing due to the amount of citrus in them.";
    } else if (cocktailCategory === "Sidecar") {
        body += "The cocktail is balanced by a significant amount of liqueur and citrus - usually added to enhance the qualities of the main spirit.";
    } else if (cocktailCategory === "Whiskey Highball") {
        body += "These cocktails usually have a low ABV making them easy to drink, and usually consists of more liquid than other cocktaills.";
    } else if (cocktailCategory === "Old-fashioned") {
        body += "The cocktail will promote the main spirit and enhance it with a small amount of sweetener and seasoning.";
    } else if (cocktailCategory === "Flip") {
        body += "The cocktail will be very rich in taste and will work perfectly as a nightcap.";
    } else if (cocktailCategory === "Martini") {
        body += "That means the cocktail will strike a delicious balance between a spirit and an aromatic component, usually a fortified wine such as vermouth.";
    }

    return <Card key={"cocktail-category-card"} className={classes.card}>
        <CardHeader
            title={cocktailCategoryName}
            subheader={"Cocktail category"}
        />
        <CardContent>
            <Typography variant="body2">
                {body}
            </Typography>
        </CardContent>
    </Card>;
}

export function renderCaloriesCard(recipe, classes) {
    let calories = recipe.calories;

    return <Card key={"calories-card"} className={classes.card}>
        <CardHeader
            title={calories.toFixed(0) + " kcal"}
            subheader={"Caloric content"}
        />
        <CardContent>
            <Typography variant="body2" style={{marginBottom: "4px"}}>
                {"In comparison:"}
            </Typography>
            <Typography variant="body2">
                {"- a Gin & Tonic has 150 kcal"}
            </Typography>
            <Typography variant="body2">
                {"- a Martini has 190 kcal"}
            </Typography>
            <Typography variant="body2">
                {"- a glass of red wine has 120 kcal"}
            </Typography>
            <Typography variant="body2">
                {"- and a beer has 130 kcal"}
            </Typography>
        </CardContent>
    </Card>;
}

export function renderSweetnessCard(recipe, classes) {
    let sugarPerLiter = recipe.sugarPerLiter;
    let title;
    let description;
    if (sugarPerLiter < 0.5) {
        title = "Extremely dry.";
        description = "This recipe has no sugar added to it, similar to the Whiskey Highball. Even a dry white wine will have more sugar than this, and it will take a few sips to get used to the taste.";
    } else if (sugarPerLiter < 15) {
        title = "Very dry";
        description = "This recipe has very little sugar added to it, similar to a dry white wine or a Martini.";
    } else if (sugarPerLiter < 30) {
        title = "Dry";
        description = "This recipe is sweeter than a Martini, but still quite dry. It is going to taste slightly drier than an Old Fashioned, a Manhattan or a Margarita.";
    } else if (sugarPerLiter < 50) {
        title = "Moderately dry";
        description = "The Old-fashioned, Manhattan and Margarita cocktails are about as sweet as this recipe.";
    } else if (sugarPerLiter < 75) {
        title = "Moderately sweet";
        description = "This recipe is about as sweet as a dry Daiquiri.";
    } else if (sugarPerLiter < 90) {
        title = "Sweet"
        description = "This recipe is about as sweet as a Daiquiri or a Mojito. Most people will find this level of sweetness pleasant.";
    } else if (sugarPerLiter < 120) {
        title = "Very sweet"
        description = "This recipe is in the sweeter end of recipes. Beginners to cocktails will find this level of sweetness pleasant, while it may alienate more experienced drinkers.";
    } else if (sugarPerLiter < 150) {
        title = "Extremely sweet"
        description = "Perfect for the uncomplicated days, this recipe is very sweet and will be a hit with people who like sweet drinks. A Pina Colada is about as sweet as this recipe.";
    } else {
        title = "Too sweet";
        description = "You should cut down on the sugar in this recipe. It is sweeter than a Pina Colada, and most people will find it too sweet.";
    }
    return <Card key={"sweetness-card"} className={classes.card}>
        <CardHeader
            title={title}
            subheader={"Sweetness"}
        />
        <CardContent>
            <Typography variant="body2" style={{marginBottom: "4px"}}>
                {description}
            </Typography>
            <Typography variant="body2" style={{marginBottom: "4px"}}>
                {"The recipe has " + sugarPerLiter.toFixed(0) + " grams of sugar per liter."}
            </Typography>
        </CardContent>
    </Card>;
}