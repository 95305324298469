import React, {useState} from "react";
import {Typography} from "@material-ui/core";
import {Notes, PlusOne} from "@material-ui/icons";
import {
    TimelineConnector,
    TimelineContent,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from "@material-ui/lab";
import IconButton from "@material-ui/core/IconButton";
import {TastingLogDialog} from "./TastingLogDialog";
import {useMutation} from "@apollo/react-hooks";
import {DELETE_TASTING_LOG, EDIT_TASTING_LOG, GET_TASTING_LOG} from "../utilities/Queries";

interface RecipeTastingLogItemProps {
    log: any;
    index: number;
    isLastItem: boolean;
    recipe: any;
}

const RecipeTastingLogItem: React.FC<RecipeTastingLogItemProps> = ({log, index, isLastItem, recipe}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const [editTastingLog] = useMutation(EDIT_TASTING_LOG);

    const [deleteTastingLog] = useMutation(DELETE_TASTING_LOG, {
        variables: {
            tastingLogId: log.id
        },
        update(cache) {
            const data = cache.readQuery<{ recipe: { tastingLog: any[] } }>({
                query: GET_TASTING_LOG,
                variables: {recipeId: recipe.id}
            });
            const recipeData = data?.recipe;

            if (recipeData) {
                cache.writeQuery({
                    query: GET_TASTING_LOG,
                    variables: {recipeId: recipe.id},
                    data: {
                        recipe: {
                            ...recipeData,
                            tastingLog: recipeData.tastingLog.filter(tastingLog => tastingLog.id !== log.id)
                        }
                    }
                });
            }
        },
        optimisticResponse: {
            deleteTastingLog: true
        }
    });

    const handleDialogOpen = () => {
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    const hasNoComment = !log.comment;
    const icon = log.comment ? <Notes/> : <PlusOne style={{fontSize: hasNoComment ? "16px" : "24px"}}/>;
    const title = getTitle(log);
    const text = log.comment ? log.comment : undefined;
    const timelineOppositeContent = <TimelineOppositeContent
        style={{margin: 'auto 0', textAlign: index % 2 === 0 ? "right" : "left"}}
    >
        <Typography variant="body2" color="textSecondary">
            {text}
        </Typography>
    </TimelineOppositeContent>;
    const timelineContent = <TimelineContent
        style={{margin: 'auto 0', textAlign: index % 2 === 0 ? "left" : "right"}}>
        {title}
        {<Typography variant={"caption"} style={{fontStyle: "italic"}}
                     color="textSecondary">{formatDate(log.date)}</Typography>}
    </TimelineContent>;

    return (
        <>
            <TimelineItem>
                {index % 2 === 0 ? timelineOppositeContent : timelineContent}
                <TimelineSeparator>
                    <TimelineConnector/>
                    <IconButton
                        style={isLastItem ? {
                            backgroundColor: "#bdbdbd",
                            color: "white",
                            padding: "6px",
                            transition: "background-color 0.3s",
                            marginBottom: "10px"
                        } : {
                            backgroundColor: "#bdbdbd",
                            color: "white",
                            padding: "6px",
                            transition: "background-color 0.3s",

                        }}
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "rgba(212, 175, 55, 1)"}
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#bdbdbd"}
                        onClick={handleDialogOpen}
                    >
                        {icon}
                    </IconButton>
                    {!isLastItem ? <TimelineConnector/> : <div style={{height: "10px"}}/>}
                </TimelineSeparator>
                {index % 2 === 0 ? timelineContent : timelineOppositeContent}
            </TimelineItem>
            <TastingLogDialog
                open={isDialogOpen}
                onClose={handleDialogClose}
                tastingLog={log}
                onSave={(comment, date, person) => {
                    editTastingLog({
                        variables: {
                            tastingLogId: log.id,
                            comment: comment,
                            date: date,
                            person: person ? person : log.person
                        },
                        optimisticResponse: {
                            editTastingLog: {
                                __typename: "TastingLog",
                                id: log.id,
                                date: date ? date : log.date,
                                comment: comment,
                                person: person ? person : log.person
                            }
                        }
                    });
                    setIsDialogOpen(false);
                }}
                onDelete={() => {
                    deleteTastingLog();
                    setIsDialogOpen(false);
                }}
            />
        </>
    );
};

function getTitle(log) {
    const hasComment = !!log.comment;
    const title = log.person ? "Made the cocktail for " + log.person : log.comment ? "Noted thoughts" : "Had the cocktail";
    return <Typography variant={hasComment ? "body1" : "subtitle2"}>{title}</Typography>;
}

function formatDate(inputDate: any) {
    const date = new Date(inputDate);
    if (date.getFullYear() < 2019) {
        return "Unknown date";
    }
    const now = new Date();
    const oneYearAgo = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());

    if (date >= oneYearAgo) {
        return date.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'long'
        });
    } else {
        return date.toLocaleDateString('en-GB', {
            month: 'long',
            year: 'numeric'
        });
    }
}

export default RecipeTastingLogItem;