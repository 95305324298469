import * as React from 'react';
import {AppBar, Divider, TextField, Toolbar} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {Close, DeleteRounded} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Tooltip from "@material-ui/core/Tooltip";
import {PersonSelector} from "../PersonSelector";

type Props = {
    open: boolean,
    onClose: () => void,
    tastingLog?: any,
    onSave: (comment: string, date: string | null, person: string | null) => void,
    onDelete?: () => void
};
export const TastingLogDialog = ({open, onClose, tastingLog, onSave, onDelete}: Props) => {
    const [date, setDate] = React.useState<string | null>(null);
    const [person, setPerson] = React.useState<string | null>(null);
    const [comment, setComment] = React.useState(tastingLog?.comment ?? "");

    const isLargeScreen = useMediaQuery("(min-width:950px)");

    const dateTextField = <TextField
        type={"datetime-local"}
        color="secondary"
        defaultValue={tastingLog?.date ?? new Date().toISOString().slice(0, 19)}
        onChange={(event) => setDate(event.target.value)}
    />;
    const deleteButton = onDelete && <Tooltip title={"Delete log"}>
        <IconButton onClick={() => {
            onDelete();
            onClose();
        }}>
            <DeleteRounded style={{color: "red"}}/>
        </IconButton>
    </Tooltip>;
    return (
        <Dialog open={open}
                fullScreen={!isLargeScreen}
                onClose={onClose}
                fullWidth={true}
        >
            {!isLargeScreen && (
                <AppBar color="secondary" style={{position: "relative"}}>
                    <Toolbar>
                        <div className={"flex-row-space-between"}>
                            <div style={{width: "calc(100% - 48px)", display: "flex", alignItems: "center"}}>
                                <IconButton edge="start" color="inherit" onClick={onClose}
                                            aria-label="close">
                                    <Close color="primary"/>
                                </IconButton>
                                <Typography color="primary" variant="h6">
                                    {tastingLog ? "Edit log" : "Add a new log"}
                                </Typography>
                            </div>
                            {tastingLog && deleteButton}
                        </div>
                    </Toolbar>
                </AppBar>
            )}
            {isLargeScreen && <DialogTitle>
                {tastingLog ? "Edit log" : "Add a new log"}
                {tastingLog && deleteButton}
            </DialogTitle>}
            {isLargeScreen && <div style={{position: "absolute", right: 16, top: 16}}>{dateTextField}</div>}
            <DialogContent>
                {!isLargeScreen && <>
                    <div style={{marginTop: "8px"}}>
                        <Typography variant={"h6"}>
                            {"Date"}
                        </Typography>
                        <Typography variant={"body2"} style={{marginBottom: "8px"}}>
                            {"When did you have this?"}
                        </Typography>
                        {dateTextField}
                    </div>
                    <Divider style={{marginBottom: "16px", marginTop: "24px"}}/></>}
                <Typography variant={"h6"}>
                    {"Person"}
                </Typography>
                <Typography variant={"body2"} style={{marginBottom: "8px"}}>
                    {"Who did you make the cocktail for?"}
                </Typography>
                <PersonSelector defaultPerson={tastingLog?.person} onChange={(person) => setPerson(person)}/>
                <Divider style={{marginBottom: "16px", marginTop: "24px"}}/>
                <Typography variant={"h6"} style={{marginTop: "8px"}}>
                    {"Comment"}
                </Typography>
                <Typography variant={"body2"} style={{marginBottom: "16px"}}>
                    {"You can note down your thoughts here. Did you like it and what was the occasion?"}
                </Typography>
                <TextField
                    color="secondary"
                    multiline
                    label={"Comment"}
                    helperText={"Adding a comment is optional"}
                    fullWidth
                    defaultValue={tastingLog?.comment ?? ""}
                    onChange={(event) => setComment(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    variant={"text"}
                    onClick={onClose}
                    style={{color: "#cc3300"}}
                >
                    {"Cancel"}
                </Button>
                <Button variant={"contained"}
                        color={"secondary"}
                        style={{color: "white"}}
                        onClick={() => {
                            onSave(comment.trim() ? comment : null, date, person);
                            onClose();
                        }}>
                    {"Log tasting"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};