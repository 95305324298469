import React from "react";
import AdvancedFilterBlock from "./AdvancedFilterBlock";
import {InfoOutlined} from "@material-ui/icons";
import FilterDivider from "./FilterDivider";
import SliderFilterComponent from "./SliderFilterComponent";
import ChipFilterComponent from "./ChipFilterComponent";
import {SortingStrategy} from "./SortingStrategy";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export const MIN_ABV = 0;
export const MAX_ABV = 40;
export const MIN_INGREDIENTS = 1;
export const MAX_INGREDIENTS = 10;
export const MIN_ALCOHOLIC_UNITS = 0.0;
export const MAX_ALCOHOLIC_UNITS = 5.0;
export const MIN_SWEETNESS = 0;
export const MAX_SWEETNESS = 151;

export default function AdvancedFilters(props) {
    const isLargeScreen = useMediaQuery("(min-width:950px)");
    return (
        <div className="flex-column">
            {props.sortingStrategies.length > 1 && <AdvancedFilterBlock icon={<InfoOutlined/>} title="Sorting">
                <SortingStrategy chips={props.sortingStrategies.map(sortingStrategy => {
                    return {
                        key: sortingStrategy,
                        selected: props.selectedSortingStrategy !== null ? props.selectedSortingStrategy === sortingStrategy : props.preferredSortingStrategy === sortingStrategy
                    }
                })}/>
            </AdvancedFilterBlock>}
            <FilterDivider/>
            <AdvancedFilterBlock icon={<InfoOutlined/>} title="Type">
                <ChipFilterComponent chips={props.types}
                                     selectedChips={props.selectedTypes}
                                     typeName="selectedTypes"/>
            </AdvancedFilterBlock>
            <FilterDivider/>
            <AdvancedFilterBlock icon={<InfoOutlined/>} title="Amount of ingredients">
                <SliderFilterComponent value={props.minMaxIngredients}
                                       cacheName={"minMaxIngredients"}
                                       min={MIN_INGREDIENTS}
                                       max={MAX_INGREDIENTS}
                                       marks={[
                                           {
                                               value: 1,
                                               label: "1",
                                           },
                                           {
                                               value: 5,
                                               label: "5",
                                           },
                                           {
                                               value: 10,
                                               label: "10+",
                                           }
                                       ]}/>
            </AdvancedFilterBlock>
            <FilterDivider/>
            <AdvancedFilterBlock icon={<InfoOutlined/>} title="Alcohol by volume">
                <SliderFilterComponent value={props.minMaxABV}
                                       cacheName={"minMaxABV"}
                                       min={MIN_ABV}
                                       max={MAX_ABV}
                                       marks={[
                                           {
                                               value: 0,
                                               label: "0%"
                                           },
                                           isLargeScreen && {
                                               value: 7,
                                               label: "Spritz",
                                           },
                                           isLargeScreen && {
                                               value: 12,
                                               label: "Daiquiri",
                                           },
                                           {
                                               value: 20,
                                               label: "20%",
                                           },
                                           isLargeScreen && {
                                               value: 24,
                                               label: "Martini",
                                           },
                                           isLargeScreen && {
                                               value: 31,
                                               label: "Old Fashioned",
                                           },
                                           {
                                               value: 40,
                                               label: "40+",
                                           },
                                       ]}/>
            </AdvancedFilterBlock>
            <FilterDivider/>
            <AdvancedFilterBlock icon={<InfoOutlined/>} title="Alcoholic units">
                <SliderFilterComponent value={props.minMaxAlcoholicUnits}
                                       cacheName={"minMaxAlcoholicUnits"}
                                       min={MIN_ALCOHOLIC_UNITS}
                                       max={MAX_ALCOHOLIC_UNITS}
                                       step={0.1}
                                       marks={[
                                           {
                                               value: 0,
                                               label: "0",
                                           },
                                           {
                                               value: 5,
                                               label: "5+",
                                           },
                                       ]}/>
            </AdvancedFilterBlock>
            <FilterDivider/>
            <AdvancedFilterBlock icon={<InfoOutlined/>} title="Sweetness">
                <SliderFilterComponent value={props.minMaxSweetness}
                                       cacheName={"minMaxSweetness"}
                                       min={MIN_SWEETNESS}
                                       max={MAX_SWEETNESS}
                                       step={1}
                                       marks={[
                                           {
                                               value: 8,
                                               label: "Very dry",
                                           },
                                           isLargeScreen && {
                                               value: 30,
                                               label: "Dry",
                                           },
                                           {
                                               value: 60,
                                               label: "Moderate",
                                           },
                                           {
                                               value: 105,
                                               label: "Sweet",
                                           },
                                           isLargeScreen && {
                                               value: 151,
                                               label: "Extremely sweet",
                                           },
                                       ]}/>
            </AdvancedFilterBlock>
            <FilterDivider/>
            {props.personalFilters.length > 0 && <>
                <AdvancedFilterBlock icon={<InfoOutlined/>} title="Personal filters">
                    <ChipFilterComponent chips={props.personalFilters}
                                         selectedChips={props.selectedPersonalFilters}
                                         typeName="selectedPersonalFilters"/>
                </AdvancedFilterBlock>
                <FilterDivider/>
            </>
            }
            <AdvancedFilterBlock icon={<InfoOutlined/>} title="Construction method">
                <ChipFilterComponent chips={props.buildTypes}
                                     selectedChips={props.selectedBuildTypes}
                                     typeName="selectedBuildTypes"/>
            </AdvancedFilterBlock>
            <FilterDivider/>
            <AdvancedFilterBlock icon={<InfoOutlined/>} title="Cocktail category">
                <ChipFilterComponent chips={props.cocktailCategories}
                                     selectedChips={props.selectedCocktailCategories}
                                     typeName="selectedCocktailCategories"/>
            </AdvancedFilterBlock>
            <FilterDivider/>
            <AdvancedFilterBlock icon={<InfoOutlined/>} title="Book">
                <ChipFilterComponent chips={props.books}
                                     selectedChips={props.selectedBooks}
                                     typeName="selectedBooks"/>
            </AdvancedFilterBlock>
        </div>
    );
}