import * as React from 'react';
import {useQuery} from "@apollo/react-hooks";
import {GET_PEOPLE} from "./utilities/Queries";
import {InputBase, Tab, Tabs} from "@material-ui/core";

type Props = {
    onChange: (person: string | null) => void;
    defaultPerson?: string;
};
export const PersonSelector = ({onChange, defaultPerson}: Props) => {
    const myselfName = "Myself";
    const [selectedPerson, setSelectedPerson] = React.useState<string | null>(defaultPerson ? defaultPerson : myselfName);
    const {loading, error, data} = useQuery(GET_PEOPLE);

    let people = [myselfName];
    if (defaultPerson) {
        people = people.concat(defaultPerson);
    }
    if (data) {
        people = people.concat(data.people.filter((person: string) => person !== defaultPerson));
    }

    const updatePerson = (newValue: string) => {
        const person = newValue === myselfName ? null : newValue;
        onChange(person);
        if (person === null || people.includes(newValue)) {
            setSelectedPerson(newValue);
        }
    };
    return (
        <Tabs variant={"scrollable"} value={selectedPerson} color="secondary"
              onChange={(event, newValue) => updatePerson(newValue)}>
            {people.map(person => (
                <Tab key={person} label={person} value={person} style={{minWidth: "80px", textTransform: "none"}}/>
            ))}
            <Tab key={"Add"}
                 disableRipple
                 label={<InputBase
                     placeholder={"Add someone new"}
                     inputProps={{'aria-label': 'naked'}}
                     onFocus={() => setSelectedPerson("Add")}
                     onChange={(event) => onChange(event.target.value)}
                 />}
                 value={"Add"}
                 style={{minWidth: "80px"}}/>
        </Tabs>
    );
};