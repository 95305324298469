import gql from "graphql-tag";

export const COCKTAIL_MENU_DATA = gql`
  fragment StandardData on CocktailMenu {
    id
    name
    description
    categoryDescriptions {
      category
      description
    }
    cocktailMenuItems {
        id
        name
        book {
            id
            name
        }
        ingredientNames
        abv
        amount
        category
    }
  }
`;

export const GET_COCKTAIL_MENU = gql`
query getCocktailMenu($id: String!) {
  cocktailMenu(id: $id) {
    ...StandardData
  }
}
  ${COCKTAIL_MENU_DATA}
`;

export const GET_COCKTAIL_MENUS = gql`
query getCocktailMenus {
  cocktailMenus {
    id
    name
    description
  }
}
`;

export const SAVE_MENU_ITEMS_ORDER = gql`
  mutation saveMenuItemsOrder($menuId: ID!, $items: [String!]!) {
    saveMenuItemsOrder(menuId: $menuId, items: $items) {
        ...StandardData
    }
  }
  ${COCKTAIL_MENU_DATA}
`;

export const DELETE_MENU_ITEM = gql`
  mutation deleteMenuItem($menuId: ID!, $id: ID!) {
    deleteMenuItem(menuId: $menuId, id: $id) {
        ...StandardData
    }
  }
  ${COCKTAIL_MENU_DATA}
`;

export const EDIT_MENU_ITEM = gql`
  mutation editMenuItem($menuId: ID!, $id: ID!, $category: String) {
    editMenuItem(menuId: $menuId, id: $id, category: $category) {
        ...StandardData
    }
  }
  ${COCKTAIL_MENU_DATA}
`;

export const ADD_MENU_ITEM = gql`
  mutation addMenuItem($menuId: ID!, $id: ID!) {
    addMenuItem(menuId: $menuId, id: $id) {
        ...StandardData
    }
  }
  ${COCKTAIL_MENU_DATA}
`;

export const SAVE_TITLE = gql`
  mutation saveCocktailMenuTitle($menuId: ID!, $text: String!) {
    saveCocktailMenuTitle(menuId: $menuId, text: $text) {
        ...StandardData
    }
  }
  ${COCKTAIL_MENU_DATA}
`;

export const SAVE_DESCRIPTION = gql`
  mutation saveCocktailMenuDescription($menuId: ID!, $text: String!) {
    saveCocktailMenuDescription(menuId: $menuId, text: $text) {
        ...StandardData
    }
  }
  ${COCKTAIL_MENU_DATA}
`;

export const SAVE_CATEGORY_DESCRIPTIONS = gql`
  mutation saveCategoryDescriptions($menuId: ID!, $categoryDescriptions: [CategoryDescriptionInput!]!) {
    saveCategoryDescriptions(menuId: $menuId, categoryDescriptions: $categoryDescriptions) {
        ...StandardData
    }
  }
  ${COCKTAIL_MENU_DATA}
`;

export const CREATE_COCKTAIL_MENU = gql`
  mutation createCocktailMenu($name: String!) {
    createCocktailMenu(name: $name) {
        ...StandardData
    }
  }
  ${COCKTAIL_MENU_DATA}
`;

export const DELETE_COCKTAIL_MENU = gql`
  mutation deleteCocktailMenu($menuId: ID!) {
    deleteCocktailMenu(menuId: $menuId)
  }
`;