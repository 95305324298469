import React, {JSX, useEffect} from "react";
import RecipesCard from "./RecipesCard";
import {useQuery} from "@apollo/react-hooks";
import {GET_RECIPES} from "../utilities/Queries";
import {mapFilters, mapSearchTerms} from "../utilities/Utilities";
import InfiniteScrollContainer from "../utilities/InfiniteScrollContainer";
import RecipesLoading from "./RecipesLoading";
import {client} from "../ApolloLayer";
import {useLocation} from "react-router-dom";
import {CreateRecipeCard} from "./CreateRecipeCard";

export const LIMIT = 30;

function isMappedFiltersEmpty(mappedFilters: {
    selectedBuildTypes: string[];
    selectedSortingStrategy: string | null;
    minMaxIngredients: number[];
    selectedTypes: string[];
    selectedBooks: string[];
    selectedCocktailCategories: string[];
    selectedPersonalFilters: string[];
    minMaxABV: number[];
    minMaxAlcoholicUnits: number[];
}): boolean {
    return mappedFilters.selectedBuildTypes.length === 0
        && mappedFilters.selectedSortingStrategy === null
        && mappedFilters.minMaxIngredients[0] === 1
        && mappedFilters.minMaxIngredients[1] === 10
        && mappedFilters.selectedTypes.length === 1
        && mappedFilters.selectedTypes[0] === "Cocktail"
        && mappedFilters.selectedBooks.length === 0
        && mappedFilters.selectedCocktailCategories.length === 0
        && mappedFilters.selectedPersonalFilters.length === 0
        && mappedFilters.minMaxABV[0] === 0
        && mappedFilters.minMaxABV[1] === 40
        && mappedFilters.minMaxAlcoholicUnits[0] === 0
        && mappedFilters.minMaxAlcoholicUnits[1] === 5;
}

export default function Recipes(props): JSX.Element {
    const {
        bookTitle,
        searchTerms,
        selectedSortingStrategy,
        selectedBuildTypes,
        selectedTypes,
        selectedCocktailCategories,
        selectedPersonalFilters,
        selectedBooks,
        minMaxIngredients,
        minMaxABV,
        minMaxAlcoholicUnits,
        minMaxSweetness
    } = props;

    const location = useLocation(); // Hook to track URL changes
    // Effect to clear cache when URL changes
    useEffect(() => {
        client.cache.evict({id: "ROOT_QUERY", fieldName: "recipes"});
        client.cache.gc(); // Run garbage collection to clean up evicted data
    }, [location]);

    let mappedSearchTerms = mapSearchTerms(searchTerms);
    let mappedFilters = mapFilters(selectedSortingStrategy, minMaxIngredients, selectedBuildTypes, selectedTypes, selectedCocktailCategories, selectedPersonalFilters, selectedBooks, minMaxABV, minMaxAlcoholicUnits, minMaxSweetness);
    let getRecipesVariables;
    if (bookTitle !== undefined) {
        getRecipesVariables = {
            filters: mappedSearchTerms,
            advancedFilters: mappedFilters,
            offset: 0,
            limit: LIMIT
        };
    } else {
        getRecipesVariables = {
            filters: mappedSearchTerms,
            advancedFilters: mappedFilters,
            offset: 0,
            limit: LIMIT
        };
    }

    const {loading: recipesLoading, error: recipesError, data: recipesData, fetchMore} = useQuery(GET_RECIPES,
        {
            variables: getRecipesVariables,
            client: client
        });
    if (recipesLoading) {
        return <RecipesLoading/>;
    }
    if (recipesError) {
        if (recipesError) {
            console.log(recipesError);
            return <div>{recipesError.message}</div>;
        }
    }
    let recipes = recipesData.recipes;
    let recipesLength = recipesData.recipesLength;
    let titleText = "Showing " + recipes.length + " out of " + recipesLength[1] + " total recipes";
    if (recipesLength[0] > 0) {
        titleText += ", of which you can make " + recipesLength[0] + " recipes";
    }
    titleText += ".";
    return (
        <div className="recipes">
            <div className="total-recipes">{titleText}</div>
            <InfiniteScrollContainer
                fetchMoreMainData={() => {
                    const newOffset = recipes.length;
                    fetchMore({
                        variables: {
                            ...getRecipesVariables, // Keep existing filters and limits
                            offset: newOffset // Update the offset
                        },
                        updateQuery: (prev, {fetchMoreResult}) => {
                            if (!fetchMoreResult) {
                                return prev;
                            }
                            return {
                                ...prev,
                                recipes: [...prev.recipes, ...fetchMoreResult.recipes]
                            };
                        }
                    });
                }}
                recipesLength={recipesLength[1]}
                key={"infinite-scroll-container"}>
                {[
                    ...(mappedSearchTerms.length === 0 && isMappedFiltersEmpty(mappedFilters) ? [<CreateRecipeCard
                        key="create-recipe-card"/>] : []),
                    ...renderRecipes(recipes, false, getRecipesVariables, undefined, props.isLoggedIn)
                ]}
            </InfiniteScrollContainer>
        </div>
    );
}

export function renderRecipes(recipes, renderVariants, getRecipesVariables, getLinkedRecipesVariables, isLoggedIn) {
    return recipes.map((recipe) => (
        <RecipesCard key={recipe.id}
                     isLoggedIn={isLoggedIn}
                     recipe={recipe}
                     isVariant={renderVariants}
                     getRecipesVariables={getRecipesVariables}
                     getLinkedRecipesVariables={getLinkedRecipesVariables}/>
    ));
}